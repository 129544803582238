import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {
    Checkbox,
    Chip,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import roles from "../../Services/roleService";
import { sensorBatteryLevel } from "../../Services/sensorService";
import { scaleToRange } from "../../Utils/mathUtils";
import { getScreedTypeName } from "../../Utils/sensorUtils";

const styles = {
    tableRowYellow: {
        backgroundColor: "#fffde7",
        "&:hover": {
            backgroundColor: "#fff9c4 !important",
        },
        "&.Mui-selected": {
            backgroundColor: "#fff59d !important",
        },
    },
    tableRowRed: {
        backgroundColor: "#ffebee",
        "&:hover": {
            backgroundColor: "#ffcdd2 !important",
        },
        "&.Mui-selected": {
            backgroundColor: "#ef9a9a !important",
        },
    },
};

const screedWarningCloseToSurface =
    "The sensor is too close to the surface, it probably shows the RH value in the room, not the screed.";
const screedWarningAboveSurface =
    "The sensor is above the surface – wrong mounting.";
const screedWarningTooDeep =
    "The Screed Depth is larger than 100 mm, the shown RH may be incorrect.";
const screedWarningUncalibrated =
    "The Screed has not be calibrated for this combination of block size and screed depth, the shown RH is not reliable.";
const screedWarningMissingData =
    "Not able to calculate corrected RH due to missing screed data. Uncorrected RH will be shown instead.";
const screedWarningIsOther =
    "Not able to calculate corrected RH due to screed type being 'Other'. Uncorrected RH will be shown instead.";

const screedDepthConditions = {
    "Combimix 920 industri": {
        20: (depth) => depth >= 40,
        35: (depth) => depth >= 40,
        default: (depth) => depth >= 60,
    },
    "Combimix Projekt fin": {
        20: (depth) => depth >= 50,
        35: (depth) => depth >= 40,
        default: (depth) => depth >= 60,
    },
    "Combimix Projekt grov": {
        20: (depth) => depth >= 60,
        default: (depth) => depth >= 80,
    },

    "Combimix Projekt snabb": {
        20: (depth) => depth >= 60,
        35: (depth) => depth >= 90,
        default: () => false,
    },

    "StoCrete CS630": {
        20: (depth) => depth >= 60,
        35: (depth) => depth >= 70,
        default: (depth) => depth >= 80,
    },
    "StoCrete CS635": {
        20: (depth) => depth >= 50,
        default: (depth) => depth >= 80,
    },
    "StoCrete CS735": {
        20: (depth) => depth >= 60,
        default: (depth) => depth >= 70,
    },

    "Weberfloor 130 Core": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 90,
        default: (depth) => depth >= 60 && depth < 70,
    },
    "Weberfloor 130/110": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 90,
        default: (depth) => depth >= 90,
    },
    "Weberfloor 130 Nova": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 90,
        default: (depth) => depth >= 90,
    },
    "Weberfloor 110 Fine": {
        20: (depth) => depth >= 50,
        35: (depth) => depth >= 40,
        default: (depth) => depth >= 60,
    },
    "Weberfloor 4602 Industry": {
        20: (depth) => depth >= 50,
        35: (depth) => depth >= 40,
        default: (depth) => depth >= 60,
    },
    "Weber 120": {
        20: (depth) => depth >= 60,
        35: (depth) => depth >= 70,
        default: (depth) => depth >= 60,
    },

    "Kiilto Express": {
        default: (depth) => depth >= 60,
    },
    "Kiilto Kombiflyt": {
        20: (depth) => depth >= 60,
        35: (depth) => depth >= 70,
        default: (depth) => depth >= 60,
    },
    "Kiilto Multi": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 80,
        default: (depth) => depth >= 60 && depth < 70,
    },
    "Kiilto Pro rECO": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 80,
        default: (depth) => (depth >= 60 && depth < 70) || depth >= 90,
    },
    "Kiilto Rotavjämning": {
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 70,
        default: (depth) => depth >= 60,
    },

    "Mapei Conplan Pro Base": {
        20: (depth) => depth >= 60,
        default: false,
    },
    "Mapei Uniplan ECO": {
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 60,
        default: (depth) => depth >= 60,
    },
    "Mapei Uniplan ECO LC": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 80,
        default: (depth) => (depth >= 60 && depth < 70) || depth >= 100,
    },

    "Hey'di Proplan Multi": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 80,
        default: (depth) => (depth >= 60 && depth < 70) || depth > 80,
    },

    "MCT 85": {
        20: (depth) => depth >= 60,
        35: (depth) => (depth >= 40 && depth < 50) || depth >= 80,
        default: (depth) => (depth >= 60 && depth < 70) || depth >= 110,
    },
};

function screedDataIsUncalibrated(typeName, depth, blockSize) {
    const depthCheckFunction =
        screedDepthConditions[typeName]?.[blockSize] ||
        screedDepthConditions[typeName]?.default;

    if (typeof depthCheckFunction === "function") {
        return depthCheckFunction(depth);
    }

    return false;
}

export default function SensorListRow({
    sensor,
    handleClick,
    addSensorPosition,
    handleRemoveSensorPosition,
    setShowAddDataDialog,
    setSelectedSensor,
    role,
    sensorType,
    isItemSelected,
}) {
    const [rowStyle, setRowStyle] = useState(null);
    const [warningText, setWarningText] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const [batteryLevel, setBatterylevel] = useState(null);
    const [batteryLevelText, setBatterylevelText] = useState("");

    const isConcrete = sensorType === "concrete";
    const isScreed = sensorType === "screed";
    const isScreedActive = sensorType === "activescreed";

    const updateScreedWarning = useCallback(async () => {
        const { screedType, screedDepth, screedBlockSize } = sensor;
        const typeName = await getScreedTypeName(screedType);

        if (typeName === "Other") {
            setRowStyle(styles.tableRowYellow);
            setWarningText(screedWarningIsOther);
            setShowWarning(true);
        } else if (screedDepth && screedBlockSize && screedType) {
            const diff = screedDepth - screedBlockSize;

            if (screedDepth > 100) {
                setRowStyle(styles.tableRowYellow);
                setWarningText(screedWarningTooDeep);
                setShowWarning(true);
            } else if (diff <= 0) {
                /* 
                    Note: A red depth warning is only shown if the block size is larger than or equal
                    to the screed depth. However the specs could be interpreted as the warning should
                    be shown if for example block size === 35 and depth === 39, or block size === 50
                    and depth === 59. According to Predrag this should not be the case and we should
                    contiue showing the red warning when the block size is larger than or equal to the
                    depth as we do currently.
                */
                setRowStyle(styles.tableRowRed);
                setWarningText(screedWarningAboveSurface);
                setShowWarning(true);
            } else if (diff < 10) {
                setRowStyle(styles.tableRowYellow);
                setWarningText(screedWarningCloseToSurface);
                setShowWarning(true);
            } else if (
                screedDataIsUncalibrated(typeName, screedDepth, screedBlockSize)
            ) {
                setRowStyle(styles.tableRowYellow);
                setWarningText(screedWarningUncalibrated);
                setShowWarning(true);
            }
        } else {
            setRowStyle(styles.tableRowYellow);
            setWarningText(screedWarningMissingData);
            setShowWarning(true);
        }
    }, [sensor]);

    const updateBatteryLevel = useCallback(async () => {
        let level = await sensorBatteryLevel(
            sensor.deveui,
            isConcrete ? "concrete" : "activescreed"
        );

        level = level.data.batteryLevel;
        if (level === 0) {
            setBatterylevel("Charging");
            setBatterylevelText("Battery is charging");
        } else if (!level || level === 255) {
            setBatterylevel("N/A");
            setBatterylevelText("Unable to get battery level");
        } else {
            // 1 = empty, 254 = full
            const parsedLevel = Math.ceil(scaleToRange(level, 1, 254, 0, 100));
            setBatterylevel(`${parsedLevel}%`);
            setBatterylevelText("");
        }
    }, [sensor, isConcrete]);

    useEffect(() => {
        async function fetchData() {
            if (isScreed) {
                updateScreedWarning();
            } else if (isConcrete) {
                updateBatteryLevel();
            } else if (isScreedActive) {
                updateScreedWarning();
                updateBatteryLevel();
            }
        }

        fetchData();
    }, [
        sensor,
        isScreed,
        isConcrete,
        isScreedActive,
        updateScreedWarning,
        updateBatteryLevel,
    ]);

    return (
        <TableRow hover key={sensor.id} selected={isItemSelected} sx={rowStyle}>
            <TableCell padding="checkbox">
                <Checkbox
                    onClick={(event) => handleClick(event, sensor)}
                    checked={isItemSelected}
                />
            </TableCell>
            <TableCell padding="normal">{sensor.serialNumber}</TableCell>
            <TableCell padding="normal">{sensor.name}</TableCell>
            <TableCell padding="normal">
                <Chip
                    style={{
                        backgroundColor: sensor.color,
                    }}
                />
            </TableCell>
            {(isConcrete || isScreedActive) && (
                <TableCell>
                    <Tooltip title={batteryLevelText}>
                        <div>{batteryLevel}</div>
                    </Tooltip>
                </TableCell>
            )}

            {role !== roles.Measurer && (
                <TableCell padding="none">
                    {sensor.positionX === null ? (
                        <IconButton
                            value={sensor}
                            onClick={() => addSensorPosition(sensor)}
                            style={{ color: "green" }}
                        >
                            <AddIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            value={sensor}
                            onClick={() => handleRemoveSensorPosition(sensor)}
                            style={{ color: "red" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </TableCell>
            )}

            {!(isConcrete || isScreedActive) ? (
                <TableCell padding="normal">
                    <Chip
                        label="Add data"
                        icon={
                            <AddIcon
                                style={{
                                    color: "green",
                                }}
                            />
                        }
                        onClick={() => {
                            setShowAddDataDialog(true);
                            setSelectedSensor(sensor);
                        }}
                    />
                </TableCell>
            ) : null}

            {(isScreed || isScreedActive) && (
                <TableCell padding="checkbox">
                    {showWarning && (
                        <Tooltip
                            arrow
                            title={
                                <Typography sx={{ fontSize: 14 }}>
                                    {warningText}
                                </Typography>
                            }
                        >
                            <PriorityHighIcon
                                style={{
                                    color: "black",
                                }}
                            />
                        </Tooltip>
                    )}
                </TableCell>
            )}
        </TableRow>
    );
}
